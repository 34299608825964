import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    return ( <section className="section section-lg thanks">
        <div className="container">
           <h1>{t('flynas_survey_portal')}</h1>
        </div>
    </section>)
}

export default Home;