import OneChoiceQuestion from "../components/OneChoiceQuestion";
import MultipleChoiceQuestion from "../components/MultipleChoiceQuestion";
import TextQuestion from "../components/TextQuestion";
import OneChoiceWithOpenAnswer from "../components/OneChoiceWithOpenAnswer";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import { useTranslation } from 'react-i18next';

const PassengerSurvey = () => {

    const { t, i18n } = useTranslation();
    const [questions, setQuestions] = useState([{ id: "0" }]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [surveyErrorCode, setSurveyErrorCode] = useState('');
    const [surveyType, setSurveyType] = useState('');
    const navigate = useNavigate();

    const getSurveyParameters = () => {
        let currentLocation = window.location.pathname;
        let encodesParams = currentLocation.split('/')[2];
        let params = atob(encodesParams).split('|');
        let language = i18n.language;
        if (params[14] && params[14].trim() !== "") {
            language = params[14].trim(); 
            i18n.changeLanguage(language); 
        }
        let data = {
            email: params[1],
            workPhone: params[2],
            pnr: params[3],
            flightNumber: params[4].trim(),
            departureStation: params[5].trim(),
            arrivalStation: params[6].trim(),
            departureDate: params[7].trim(),
            language: language,
            channel: 0
        };
        if (params.length > 8) {
            data.isInternational = params[8].trim() === "True";
            data.fromCountry = params[9].trim();
            data.toCountry = params[10].trim();
            data.flightSTDUTC = params[11].trim();
            data.flightSTAUTC = params[12].trim();
            if (!params[13]) {
                data.surveyType = "Nps";
                setSurveyType("Nps");
            } else {
                data.surveyType = params[13].trim();
                setSurveyType(data.surveyType);
            }
        }
        return data;
    }

    useEffect(() => {
        const surveyParams = getSurveyParameters();
        const queryString = `?email=${surveyParams.email}&workPhone=${surveyParams.workPhone}&homePhone=${surveyParams.homePhone}&pnr=${surveyParams.pnr}` +
            `&flightNumber=${surveyParams.flightNumber}&departureStation=${surveyParams.departureStation}&arrivalStation=${surveyParams.arrivalStation}&departureDate=${surveyParams.departureDate}`
            + `&surveyType=${surveyParams.surveyType}`;

        axios.get(`${process.env.REACT_APP_BASE_URL}/Survey/passenger-questions${queryString}`)
            .then((response) => {
                setQuestions(response.data);
                setCurrentQuestion(questions[0]);
            }).catch(error => {
                setSurveyErrorCode(error.response.data.errorCode);
            });

    }, [questions[0].id])


    const setQuestionAnswer = (answer, nextQuestionId, isAdding, openAnswer = '') => {
        if (currentQuestion.type === "multipleChoice" && currentQuestion.answer) {
            if (isAdding)
                answer = currentQuestion.answer + "," + answer;
            else {
                let answers = currentQuestion.answer.split(",");
                answers = answers.filter(a => a !== answer);
                answer = answers.join(",");
            }
        }
        if (currentQuestion.type === "text") {
            const expr = /(<([^>]+)>)/ig;
            const found = answer.match(expr);
            if (found && found.length > 0) {
                setCurrentQuestion((oldQuestion) => ({ ...oldQuestion, answer: '', nextQuestionId: nextQuestionId }));
                return;
            }
        }
        if (currentQuestion.type === "oneChoiceWithOpenAnswer") {
            let optionHasText = currentQuestion.options.find(option => option.value === answer).withText
            if (optionHasText && !openAnswer) {
                setCurrentQuestion((oldQuestion) => ({ ...oldQuestion, answer: '', nextQuestionId: nextQuestionId }));
                return;
            }
        }
        setCurrentQuestion((oldQuestion) => ({
            ...oldQuestion, answer: answer, nextQuestionId: nextQuestionId
            , openAnswer: openAnswer
        }))
    }

    const goToNextQuestion = () => {
        const nextQuestion = questions.find(question => question.id === currentQuestion.nextQuestionId);
        let newQuestionsList = JSON.parse(JSON.stringify(questions));
        newQuestionsList[currentQuestionIndex] = JSON.parse(JSON.stringify(currentQuestion));
        setQuestions((oldQuestions) => (newQuestionsList));
        setCurrentQuestion(nextQuestion);
        setCurrentQuestionIndex(questions.indexOf(nextQuestion));
    }

    const saveQuestions = async () => {
        let questionAnswer = getSurveyParameters();
        questionAnswer.answers = questions.filter(question => question.answer)
            .map(question => {
                return {
                    questionId: question.id,
                    answer: question.answer,
                    openAnswer: question.openAnswer
                }
            });
        questionAnswer.answers.push({ questionId: currentQuestion.id, answer: currentQuestion.answer });

        axios.post(`${process.env.REACT_APP_BASE_URL}/Survey/passenger-questions`, questionAnswer)
            .then(response => {
                navigate(`/thank-you`)
            })
    }

    return (
        <div>
            <section className="section section-lg welcome">
                <div className="container">
                    <h1>
                        {t('thanks_for_choose_flynas')}
                    </h1>
                    <h2>{t('help_to_improve_experience')}</h2>
                </div>
            </section>
            {
                currentQuestion ?
                    <>
                        {
                            currentQuestion.type === "oneChoice" ?
                                <OneChoiceQuestion question={currentQuestion} setQuestionAnswer={setQuestionAnswer} /> :
                                currentQuestion.type === "multipleChoice" ?
                                    <MultipleChoiceQuestion question={currentQuestion}
                                        setQuestionAnswer={setQuestionAnswer} /> :
                                    currentQuestion.type === "text" ?
                                        <TextQuestion question={currentQuestion} setQuestionAnswer={setQuestionAnswer}
                                            surveyType={surveyType} /> :
                                        currentQuestion.type === "oneChoiceWithOpenAnswer" ?
                                            <OneChoiceWithOpenAnswer question={currentQuestion}
                                                setQuestionAnswer={setQuestionAnswer} /> :
                                            <div>No template for this question type</div>
                        }
                        <button className="btnSubmit" disabled={!currentQuestion.answer}
                            onClick={() => currentQuestionIndex === questions.length - 1 ?
                                saveQuestions() : goToNextQuestion()}>
                            {
                                currentQuestionIndex === questions.length - 1 ?
                                    <span>{t('submit')}</span> : <span>{t('next')}</span>
                            }
                        </button>
                    </> : surveyErrorCode && surveyErrorCode === "11" ?
                        <h3>{t('survey_answered_previously')}</h3> :
                        <h3>{t('load_survey')} .....</h3>
            }
            <br /><br />
        </div>
    )
}

export default PassengerSurvey