import { useTranslation } from 'react-i18next';

const TextQuestion = ({ question: { textArabic, textEnglish, options }, setQuestionAnswer,surveyType }) => {

    const { i18n } = useTranslation();

    return (<section className="section section-lg">
        <div className={`container rborder ${surveyType ? surveyType.toLowerCase():''}`}>
            <h3>{i18n.language === "en" ? textEnglish : textArabic}</h3>
            <div className="number-btn-section">
                {options.map((option) => (
                    <textarea style={{ width: "80%" }} rows="5" onChange={(event) =>
                        setQuestionAnswer(event.target.value,option.nextQuestionId)} />

                ))}
            </div>
        </div>
    </section>)
}

export default TextQuestion