import { useTranslation } from 'react-i18next';

 const ThankYou=()=> {
     
     const { t } = useTranslation();
     
    return (<div>
        <section className="section section-lg thanks">
            <div className="container">
                <h1>{t('thank_you')}</h1>
                <h2>{t('for_sharing_reviews')}</h2>
            </div>
        </section>
    </div>)
}

export default ThankYou