import { useTranslation } from "react-i18next";

const OneChoiceQuestion = ({
  question: {
    textArabic,
    textEnglish,
    options,
    leftTextArabic,
    leftTextEnglish,
    rightTextArabic,
    rightTextEnglish,
  },
  setQuestionAnswer,
}) => {
  const { i18n } = useTranslation();

  const getOptionClass = (value) => {
    switch (value) {
      case "1":
        return "one";
      case "2":
        return "two";
      case "3":
        return "three";
      case "4":
        return "four";
      case "5":
        return "five";
      case "6":
        return "six";
      case "7":
        return "seven";
      case "8":
        return "eight";
      case "9":
        return "nine";
      case "10":
        return "ten";
      default:
        return "";
    }
  };

  return (
    <section className="section section-lg">
      <div className="container rborder">
        <h3>{i18n.language === "en" ? textEnglish : textArabic}</h3>
        <div className="number-btn-section">
          <div className="btn-label-text">
            <span className={i18n.language === "en" ? "left" : "right"}>
              {i18n.language === "en" ? leftTextEnglish : leftTextArabic}
            </span>
            <span className={i18n.language === "en" ? "right" : "left"}>
              {i18n.language === "en" ? rightTextEnglish : rightTextArabic}
            </span>
          </div>
          <div>
            {" "}
            {options.map((option) => (
              <button
                className={getOptionClass(option.value)}
                type=" button"
                onClick={() =>
                  setQuestionAnswer(option.value, option.nextQuestionId)
                }
                key={option.value}
              >
                {i18n.language === " en"
                  ? option.textEnglish
                  : option.textArabic}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OneChoiceQuestion;
