const Footer = () => {
    return (
        <footer>
            <div className="container">
                <table align="center" cellPadding="0" cellSpacing="0" border="0" summary="">
                    <tr>
                        <td>
                            <a href="https://www.flynas.com/en/">
                                <img className="f-logo"
                                     src={`${process.env.PUBLIC_URL}/assets/images/flynas-footer-logo.png`} border="0"
                                     width="184"
                                     alt=""/>
                            </a>
                        </td>
                        <td style={{paddingTop: "15px", paddingLeft: "20px", paddingBlock: "10px"}}>
                            <table cellPadding="1" cellSpacing="0" border="0" summary="">
                                <tr>
                                    <td><a
                                        style={{
                                            textDecoration: "none",
                                            marginRight: "8px",
                                            width: "29px",
                                            height: "29px",
                                            display: "block"
                                        }}
                                        href="https://www.facebook.com/flynas/"><img
                                        src={`${process.env.PUBLIC_URL}/assets/images/facebook-icon.png`}
                                        width="29" height="29" alt=""/></a>
                                    </td>
                                    <td><a
                                        style={{
                                            textDecoration: "none",
                                            marginRight: "8px",
                                            width: "29px",
                                            height: "29px",
                                            display: "block"
                                        }}
                                        href="https://twitter.com/flynas/"><img
                                        src={`${process.env.PUBLIC_URL}/assets/images/twitter-icon.png`}
                                        width="29" height="29" alt=""/></a>
                                    </td>
                                    <td><a
                                        style={{
                                            textDecoration: "none",
                                            marginRight: "8px",
                                            width: "29px",
                                            height: "29px",
                                            display: "block"
                                        }}
                                        href="https://www.instagram.com/flynas/"><img
                                        src={`${process.env.PUBLIC_URL}/assets/images/insta-icon.png`}
                                        width="29" height="29"
                                        alt=""/></a></td>
                                    <td><a
                                        style={{
                                            textDecoration: "none",
                                            marginRight: "8px",
                                            width: "29px",
                                            height: "29px",
                                            display: "block"
                                        }}
                                        href="https://www.youtube.com/user/flynasdotcom/"><img
                                        src={`${process.env.PUBLIC_URL}/assets/images/youtube-icon.png`} width="29" height="29" alt=""/></a>
                                    </td>
                                    <td><a
                                        style={{
                                            textDecoration: "none",
                                            marginRight: "8px",
                                            width: "29px",
                                            height: "29px",
                                            display: "block"
                                        }}
                                        href="https://www.linkedin.com/company/flynas/"><img
                                        src={`${process.env.PUBLIC_URL}/assets/images/linkedin-icon.png`} width="29" height="29" alt=""/></a>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>

            </div>

        </footer>
    )
}

export default Footer;