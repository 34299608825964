import Header from "../components/Header";
import Footer from "../components/Footer";
import {Outlet} from "react-router-dom";
import {useTranslation} from 'react-i18next';

 const Layout=()=> {
     
     const {i18n} = useTranslation();
     
    return (
        <div className={i18n.language === "en" ? "":"arabic"}>
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default Layout;