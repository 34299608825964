import {useTranslation} from 'react-i18next';

const MultipleChoiceQuestion = ({question: {textArabic, textEnglish, options}, setQuestionAnswer}) => {

    const {i18n,t} = useTranslation();

    return (
        <section className="section section-lg">
            <div className="container rborder">
                <div className="table-responsive">
                    <table className="yes-no-table">
                        <tr>
                            <th colSpan="2">
                                <h3>{i18n.language === "en" ? textEnglish : textArabic}</h3>
                                <span style={{color:'#00bfb3'}}>{t('you_can_choose_multiple_options')}</span>
                            </th>
                        </tr>
                        {
                            options.map(option =>
                                <tr key={option.value}>
                                    <th nowrap="nowrap">
                                        <label>{i18n.language === "en" ? option.textEnglish : option.textArabic}</label>
                                    </th>
                                    <td>
                                        <input type="checkbox" onChange={(event) =>
                                            setQuestionAnswer(option.value, option.nextQuestionId, event.target.checked)}/>
                                    </td>
                                </tr>
                            )
                        }
                    </table>
                </div>
            </div>
        </section>
    )
}

export default MultipleChoiceQuestion;