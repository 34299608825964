import {useTranslation} from 'react-i18next';
import {useState,useEffect} from 'react';

const OneChoiceWithOpenAnswer = ({
                                     question: {
                                         textArabic,
                                         textEnglish,
                                         options,
                                         openAnswerTitleArabic,
                                         openAnswerTitleEnglish,
                                         order,
                                         id
                                     },
                                     setQuestionAnswer
                                 }) => {
    const {i18n} = useTranslation();
    const [selectedOption, setSelectedOption] = useState(null);
    const [nextQuestionId, setnextQuestionId] = useState(null);
    const [openText, setOpenText] = useState('');

    useEffect(()=>{
        setSelectedOption(null);
        setOpenText('');
    },[id])

    const handleRadioChange = (option) => {
        setSelectedOption(option.value);
        setnextQuestionId(option.nextQuestionId);
        setQuestionAnswer(option.value, option.nextQuestionId, false, option.withText ? openText : '');
    };

    const handleTextAreaChange = (event) => {
        setOpenText(event.target.value);
        setQuestionAnswer(selectedOption, nextQuestionId, false, event.target.value);
    };

    return (
        <section className="section section-lg">
            <div className="container rborder oneChoice">
                <div>
                    <div>
                        <h3>{`${order}- `}{i18n.language === 'en' ? textEnglish : textArabic}</h3>
                        <div className="row">
                            {options.map((option) => (
                                <div className="col-md-4 oneChoiceOption">
                                    <input type="radio"
                                           value={option.value}
                                           checked={selectedOption === option.value}
                                           onChange={() => handleRadioChange(option)}
                                    />
                                    <label>{i18n.language === 'en' ? option.textEnglish : option.textArabic}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    {selectedOption && options.find((option) => option.value === selectedOption)?.withText && (
                        <div className="open-answer">
                            <h3>{i18n.language === 'en' ? openAnswerTitleEnglish : openAnswerTitleArabic}</h3>
                            <div className="number-btn-section">
                            <textarea rows="3"
                                value={openText} onChange={(event) => handleTextAreaChange(event)}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default OneChoiceWithOpenAnswer;
