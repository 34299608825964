import React from "react";
import {Route, BrowserRouter,Routes} from 'react-router-dom';
import PassengerSurvey from "./pages/PassengerSurvey";
import ThankYou from "./pages/ThankYou";
import Home from "./pages/Home";
import Layout from "./pages/Layout";


export default function App() {

    const baseRoutes =process.env.REACT_APP_SUB_DIR
    
    return (
        <BrowserRouter>
               <Routes >
                   <Route element={<Layout />}>
                       <Route index element={<Home/>} />
                       <Route path="passenger-survey/:params" element={<PassengerSurvey/>} />
                       <Route path="thank-you" element={<ThankYou/>} />
                       <Route path="*" element={<Home/>}/>
                   </Route>
               </Routes>
        </BrowserRouter>
    );
}
